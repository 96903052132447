import { createAction, props } from '@ngrx/store';

import { AbsencePolicyBatchFormRow, AbsencePolicyConfigurationModel, AbsencePolicyModel } from './absence-policy.model';

const ACTION_PREFIX = '[Absence Policy]';

export const absencePolicyAddRequest = createAction(
  `${ACTION_PREFIX} Add Request`,
  props<{ policy: AbsencePolicyModel }>()
);

export const absencePolicyAddSuccess = createAction(
  `${ACTION_PREFIX} Add Success`,
  props<{ policy: AbsencePolicyModel }>()
);

export const absencePolicyAddFailed = createAction(`${ACTION_PREFIX} Add Failed`);

export const absencePolicyLoadRequest = createAction(`${ACTION_PREFIX} Load Request`, props<{ policyId: string }>());

export const absencePolicyLoadSuccess = createAction(
  `${ACTION_PREFIX} Load Success`,
  props<{ policy: AbsencePolicyModel }>()
);

export const absencePolicyLoadFailed = createAction(`${ACTION_PREFIX} Load Failed`, props<{ policyId: string }>());

export const absencePolicyUpdateRequest = createAction(
  `${ACTION_PREFIX} Update Request`,
  props<{ policy: AbsencePolicyModel }>()
);

export const absencePolicyUpdateSuccess = createAction(
  `${ACTION_PREFIX} Update Success`,
  props<{ policy: AbsencePolicyModel }>()
);

export const absencePolicyUpdateFailed = createAction(`${ACTION_PREFIX} Update Failed`, props<{ policyId: string }>());

export const absencePolicyLoadAllRequest = createAction(`${ACTION_PREFIX} Load All Request`);

export const absencePolicyLoadAllSuccess = createAction(
  `${ACTION_PREFIX} Load All Success`,
  props<{ policies: AbsencePolicyModel[] }>()
);

export const absencePolicyLoadAllFailed = createAction(`${ACTION_PREFIX} Load All Failed`);

export const absencePolicyDeleteRequest = createAction(
  `${ACTION_PREFIX} Delete Request`,
  props<{ policyId: string }>()
);

export const absencePolicyDeleteSuccess = createAction(
  `${ACTION_PREFIX} Delete Success`,
  props<{ policyId: string }>()
);

export const absencePolicyDeleteFailed = createAction(`${ACTION_PREFIX} Delete Failed`, props<{ policyId: string }>());

export const absencePolicyAddConfigurationRequest = createAction(
  `${ACTION_PREFIX} Add Configuration Request`,
  props<{ absenceTypeId: string; policyId: string; openDialog: boolean }>()
);

export const absencePolicyUpdateConfigurationRequest = createAction(
  `${ACTION_PREFIX} Update Configuration Request`,
  props<{ configuration: AbsencePolicyConfigurationModel; policyId: string }>()
);

export const absencePolicyUpdateManyRequest = createAction(
  `${ACTION_PREFIX} Batch update Configuration Request`,
  props<{ policies: AbsencePolicyBatchFormRow[]; absenceTypeId: string }>()
);

export const absencePolicyUpdateManySuccess = createAction(
  `${ACTION_PREFIX} Batch update Configuration Success`,
  props<{ policies: AbsencePolicyModel[] }>()
);

export const absencePolicyUpdateManyFailed = createAction(`${ACTION_PREFIX} Batch update Configuration Failed`);

export const absencePolicyRemoveConfigurationRequest = createAction(
  `${ACTION_PREFIX} Remove Configuration Request`,
  props<{ absenceTypeId: string; policyId: string }>()
);
